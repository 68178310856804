.sidebar {
  flex: 1;
  border-right: 0.5px solid rgb(230, 227, 227);
  min-height: 100vh;
}
.top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  font-size: 22px;
  font-weight: bold;
  color: #191a2c;
}
hr {
  height: 0;
  border: 0.5px solid rgb(230, 227, 227);
}
.center {
  padding-left: 10px;
}
.center ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.center .link {
  text-decoration: none;
}
.center ul li {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.center ul li:hover {
  background-color: #ece9ff;
}
.icon {
  color: #191a2c;
}
.center span {
  font-size: 14px;
  font-weight: 600;
  color: #191a2c;
  margin-left: 10px;
}
