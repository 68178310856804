.add-update {
  background-color: #fff;
}
.remove-update {
  background-color: #fff;
}
.add-update:hover {
  background-color: #d3d3d3;
}
.remove-update:hover {
  background-color: #d3d3d3;
}
