.navbar {
  height: 66.6px;
  border-bottom: 0.5px solid rgb(231, 228, 228);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}
.navbar .wrapper {
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: right;
}
.search {
  display: flex;
  align-items: center;
  border: 0.5px solid lightgray;
  padding: 3px;
}
.search input {
  border: none;
  outline: none;
  background: transparent;
}
.search input::placeholder {
  font-size: 12px;
}
.items {
  display: flex;
  align-items: center;
}
.items .item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
}
.icon {
  font-size: 20px;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
