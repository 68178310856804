@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.submitbtn button {
  background-color: #3c37fe;
  font-weight: 600;
  color: #fff;
  outline: none;
}
.submitbtn button:hover {
  color: #000;
  background-color: antiquewhite;
  transition: all 0.4s ease-in-out;
}
.imglog {
  width: 100%;
  height: 520px;
}

.loginSize {
  width: 80%;
  height: 550px;
}
.loginContainer {
  background-color: #191a2c;
}
