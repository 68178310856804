.sendnotify {
  display: flex;
}
.sendnotifyContainer {
  flex: 6;
}
.changing {
}
.newUserItemSend {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newUserSelectSend {
  width: 300px;
  height: 40px;
}
