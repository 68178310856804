.list {
  display: flex;
}

.list-container {
  flex: 6;
}

.list-edit {
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #3bb;
  color: #fff;
  margin-right: 20px;
  cursor: pointer;
}

.list-delete {
  color: red;
  cursor: pointer;
}

.survey-update {
  background-color: #fff;
}

.survey-update:hover {
  background-color: #e7e7e7;
}
